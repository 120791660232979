import React, { FC } from 'react'

import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'

const NotFoundPage: FC = () => (
    <Layout>
        <SEO title='404: Not found' />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
    </Layout>
)

export default NotFoundPage
